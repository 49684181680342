/**
 * @file Mobile Scroll Helper
 * @author michael@on-running.com
 *
 * @module utility/mobile-scroll-helper
 *
 * The Mobile Scroll Helper keeps the mobile address bar and bottom bar on iOS and Android in their state when scrolling container of the passed element
 *
 */

;(function () {
  let touchstartYPosition = null

  function preventIfNeeded(e) {
    if (e.targetTouches.length === 1) {
      const scrollDistance = e.targetTouches[0].clientY - touchstartYPosition // scrollDistance > 0 => scrolling up
      const target = e.currentTarget

      // Prevent 'touchmove' when scrollable container is already at the top and user tries to scroll further up
      target.scrollTop === 0 && scrollDistance > 0 && e.preventDefault()

      // Prevent 'touchmove' when user scrolled to the end of the element and tries to scroll further down
      target.scrollHeight - target.scrollTop <= target.clientHeight &&
        scrollDistance < 0 &&
        e.preventDefault()
    }
  }

  function determineTouchstartPosition(event) {
    event.targetTouches.length === 1 && (touchstartYPosition = event.targetTouches[0].clientY)
  }

  On.initMobileScrollHelper = element => {
    element.addEventListener('touchstart', determineTouchstartPosition, false)
    element.addEventListener('touchmove', preventIfNeeded, false)
  }

  On.destroyMobileScrollHelper = element => {
    element.removeEventListener('touchstart', determineTouchstartPosition, false)
    element.removeEventListener('touchmove', preventIfNeeded, false)
  }
})()
