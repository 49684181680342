/**
 * @file Throttler
 * @author michael@on-running.com
 *
 * @module utility/throttler
 *
 * The throttler utility returns an throttler instance and can be used to execute a defined callback
 * X milliseconds after an event was triggered.
 * While the callback is executed, the callback won't be fired a second time
 *
 */

On.throttler = function () {
  const instance = {}
  let updating = false,
    executeCallback = (callback, throttleDelay) => {
      const delay = isNaN(throttleDelay) ? 0 : throttleDelay
      setTimeout(() => {
        callback()
        updating = false
      }, delay)
    },
    throttle = (callback, throttleDelay) => {
      // Only update scroll position if not still busy adding or removing classes
      if (!updating) {
        updating = true
        requestAnimationFrame(() => {
          executeCallback(callback, throttleDelay)
        })
      }
    }

  instance.throttle = throttle
  return instance
}
