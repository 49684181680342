;(function () {
  On.Cookie = {
    read: function (key) {
      var i, len, pair, ref
      ref = document.cookie.split(';')
      for (i = 0, len = ref.length; i < len; i++) {
        pair = ref[i]
        pair = pair.trim()
        if (pair.indexOf(key + '=') === 0) {
          return pair.split('=')[1]
        }
      }
      return ''
    },
    write: function (key, val, expirationDate) {
      var string
      string = `${key}=${val};path=/`
      if (expirationDate) {
        string += `;expires=${expirationDate.toGMTString()}`
      }
      return (document.cookie = string)
    },
  }
}.call(window))
