import { onDOMContentLoaded } from '@/helpers/event'

const INIT_SETTINGS = [
  {
    id: 'articles',
    file: () => import(/* webpackChunkName: "on/articles" */ '@/assets/javascripts/on/articles'),
    selectors: ['.js-masonry-layout', '.contentful-block--rich-text'],
  },
  {
    id: 'custom-select',
    file: () =>
      import(/* webpackChunkName: "on/custom-select" */ '@/assets/javascripts/on/custom-select'),
    selectors: ['body:not([data-spa])'],
  },
  {
    id: 'events',
    file: () => import(/* webpackChunkName: "on/events" */ '@/assets/javascripts/on/events'),
    selectors: ['.js-event-utilities'],
  },
  {
    id: 'form',
    file: () => import(/* webpackChunkName: "on/form" */ '@/assets/javascripts/on/form'),
    selectors: ['body:not([data-spa])', '[data-form-validation-id]'],
  },
  {
    id: 'shared',
    file: () => import(/* webpackChunkName: "on/shared" */ '@/assets/javascripts/on/shared'),
    selectors: [
      '[data-form-validation-id]',
      '.js-list-unwrap',
      '.js-picture-gallery',
      '[data-scroller-id]',
    ],
  },
  {
    id: 'utility',
    file: () => import(/* webpackChunkName: "on/utility" */ '@/assets/javascripts/on/utility'),
    selectors: [
      'body:not([data-spa])', // smooth-loader
      '.js-deferred-image',
      '.js-deferred-background-image',
      '.js-normal-background-image',
      '.js-video-aspect-ratio-keeper',
      '.js-number-only',
    ],
  },
]
const loadedLegacyUtilities = new Set([])

export async function initLegacyUtilities() {
  await onDOMContentLoaded()
  INIT_SETTINGS.forEach(({ id, file, selectors }) => {
    const element = document.querySelector(selectors.join(', '))
    if (element) file().then(() => dispatchReadyEvent(id))
  })
}

export function showSmoothLoadElementsOnNavReady() {
  window.addEventListener('on:navigation-ready', () => {
    document.querySelectorAll('.u-smooth-load').forEach(target => {
      target.classList.add('u-smooth-load--visible')
    })
  })
}

export function waitForLegacyUtility(id) {
  return new Promise(resolve => {
    loadedLegacyUtilities.has(id)
      ? resolve()
      : window.addEventListener(getEvent(id), () => resolve())
  })
}

function dispatchReadyEvent(id) {
  window.dispatchEvent(new CustomEvent(getEvent(id)))
  loadedLegacyUtilities.add(id)
}

function getEvent(id) {
  return `on:ready:${id}`
}
