/**
 * @file Debouncer
 * @author michael@on-running.com
 *
 * @module utility/debouncer
 *
 * The Debouncer utility can be used to execute a defined callback X milliseconds after an event was triggered.
 * If the same event occurs again before the callback was triggered, the callback execution is canceled and the
 * minimum wait period starts again.
 *
 */

On.Debouncer = function () {
  return {
    timer: null,
    /**
     * Initialize module
     *
     * @param {object} options
     *
     * The options object accepts the following parameters:
     * debounceTime {number} (0-1): time for how long the execution of the callback is delayed
     * callback {function} (0-1): function that gets executed after the debounceTime and if no other event cancelled the callback
     * callbackArgument {variable} (0-1): argument that can be passed along to the callback
     *
     */
    debounce: function (options) {
      clearTimeout(this.timer)
      this.timer = setTimeout(function () {
        options.callback(options.callbackArgument)
      }, options.debounceTime)
    },
  }
}
