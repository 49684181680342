/**
 * @file ContentfulImagepathOptimizer
 * @author michael@on-running.com
 *
 * @module utility/contentfulImagepathOptimizer
 *
 * The contentfulImagepathOptimizer receives a Contentful image path and returns the optimized path in terms of optimal dimensions, image quality and image type (progressive or not)
 *
 */

;(function () {
  let settings = {}
  const defaults = {
    container: '',
    desktopSrc: '',
    fit: false,
    fixedHeight: 0,
    fixedWidth: 0,
    setHeight: true,
    imageCompression: 80,
    imageMaxWidth: 3000, // Maximum allowed size by Contentful is 4000!
    mobileSrc: undefined,
    mobileImageBreakpoint: 800,
    retinaSupportFactor: 0.5,
    progressiveLoading: true,
    format: '',
    backgroundColor: '',
    devicePixelRatio: window.devicePixelRatio,
    imageSizes: [
      100, 200, 300, 375, 420, 500, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000, 2200, 2400, 2600,
      2800,
    ],
    emptyImageUrl:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
  }

  const getFittingImageSize = containerWidth => {
    let widths = settings.imageSizes
    let currentImageWidth = widths[widths.length - 1]

    const foundWidth = widths.find(width => width >= containerWidth)
    return foundWidth || currentImageWidth
  }

  const optimizeSize = currentImageSize => {
    // Limit maximum supported devicePixelRatio to '2'
    const devicePixelRatio = Math.min(settings.devicePixelRatio, 2)
    const optimalWidth = Math.min(
      Math.round(currentImageSize * (1 + (devicePixelRatio - 1) * settings.retinaSupportFactor)),
      settings.imageMaxWidth
    )
    return optimalWidth
  }

  /**
   * Defines resizing behaviour for Contentful images.
   * All about different fit options:
   *   https://www.contentful.com/developers/docs/references/images-api/#/reference/resizing-&-cropping/change-the-resizing-behavior
   * @typedef {string} ContentfulImageFit
   * @readonly
   * @enum {string}
   */

  const CONTENTFUL_IMAGE_FIT = {
    fill: 'fill',
    pad: 'pad',
    crop: 'crop',
    thumb: 'thumb',
    scale: 'scale',
  }

  const CONTENTFUL_IMAGE_FORMAT = {
    default: '',
    jpg: 'jpg',
    png: 'png',
    webp: 'webp',
  }

  /**
   * Initialize module
   *
   * @param {object} options - Override default settings with options object.
   * @param {string} options.container (0-1) - Selector of element that contains the image (e.g. '.banner'). Will use the offsetWidth/offsetHeight of the container as width/height of the image.
   * @param {string} options.desktopSrc (1-1) - Path to Contentful image.
   * @param {ContentfulImageFit} options.fit (0-1) - Defines resizing behaviour for Contentful images.
   * @param {number} options.fixedHeight (0-1) - If this option is passed, it will win over containerHeight
   * @param {number} options.fixedWidth (0-1) - Hardcoded width that wins over other width settings
   * @param {number} options.setHeight (0-1) - Determines if height parameter is set
   * @param {number} options.imageCompression (0-1) - Image compression factor
   * @param {number} options.imageMaxWidth (0-1) - Maxium width of biggest displayed image, independent of vp width
   * @param {Array.<number>} options.imageSizes  (0-1) - Breakpoint finetuning where images with different widths are loaded.
   *   Number equals breakpoint where the next bigger image in the array is loaded
   * @param {number} options.mobileBreakpoint (0-1) - Breakpoint where to load either mobile or desktop image
   * @param {string} options.mobileSrc (0-1) - Possibility to pass separate src for mobile. If not present desktop src is used
   * @param {boolean} options.progressiveLoading (0-1) - Progressive image loading enabled by default, can be turned off
   * @param {string} options.format (0-1) - jpg, png or webp. Default is the original image format.
   * @param {string} options.backgroundColor (0-1) - Hex value e.g. #f7f7f7 (with or without hash). backgroundColor is only supported when format is set
   * @param {number} options.retinaSupportFactor (0-1) - Fully supporting retina image results often in huge pictures.
   *   With the retinaSupportFactor retina can be supported only partially.
   *
   */

  const optimizeContentfulPath = options => {
    settings = Object.assign({}, defaults, options)

    const isDesktopVP = window.innerWidth > settings.mobileImageBreakpoint
    const useMobileSource = !isDesktopVP && settings.mobileSrc !== undefined
    const source = useMobileSource ? settings.mobileSrc : settings.desktopSrc
    if (!source) return settings.emptyImageUrl

    const path = source.split('?')[0]
    const container = settings.container ? document.querySelector(settings.container) : undefined
    const width = settings.fixedWidth || container?.offsetWidth || window.innerWidth
    const height = settings.fixedHeight || container?.offsetHeight || window.innerHeight

    const optimizedWidth = '?w=' + optimizeSize(getFittingImageSize(width))
    const optimizedHeight = settings.setHeight
      ? '&h=' + optimizeSize(getFittingImageSize(height))
      : ''
    const progressiveLoading =
      settings.progressiveLoading && settings.format === CONTENTFUL_IMAGE_FORMAT.jpg
        ? '&fl=progressive'
        : ''
    const quality = '&q=' + settings.imageCompression
    const fit = settings.fit ? '&fit=' + settings.fit : ''
    const backgroundColor =
      settings.backgroundColor && settings.format
        ? `&bg=rgb:${settings.backgroundColor.replace(/^#+/, '')}`
        : ''
    const format = settings.format ? `&fm=${settings.format}` : ''

    return `${path}${optimizedWidth}${optimizedHeight}${progressiveLoading}${quality}${fit}${backgroundColor}${format}`
  }

  On.optimizeContentfulPath = optimizeContentfulPath
  On.getContentfulFittingImageSize = getFittingImageSize // TODO: move to webpack once possible
  On.CONTENTFUL_IMAGE_FIT = CONTENTFUL_IMAGE_FIT
  On.CONTENTFUL_IMAGE_FORMAT = CONTENTFUL_IMAGE_FORMAT
})()
