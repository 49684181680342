if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this
    var ancestor = this
    if (!document.documentElement.contains(el)) return null
    while (ancestor !== null) {
      if (ancestor.matches(s)) return ancestor
      ancestor = ancestor.parentElement || null
    }
    return null
  }
}
