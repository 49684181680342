/**
 * @file localize
 * @author pierre-michel@on-running.com
 *
 * @module helpers/localize
 *
 * Pick the right copy from the object provided using the current language.
 * Fallback to English if the language is empty or not provided.
 *
 * @param {object} obj - { en: 'The English text', fr: 'Le texte français' }
 *
 */

On.helpers.localize = function (obj, isUsingFallback = true) {
  if (typeof obj === 'string') return obj

  const language = On.Store.state.page.language
  const getValue = value => (value && /\S/.test(value) ? value : null)

  return getValue(obj[language]) || (isUsingFallback ? getValue(obj.en) : null)
}
