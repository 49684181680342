/**
 * @file validateEmail
 * @author https://stackoverflow.com/a/1373724/1895428
 *
 * @module helpers/validate-email
 *
 * Return a boolean according to the validation of the provided email.
 *
 * @param {string} email - The email address to test
 *
 */

On.helpers.validateEmail = function (email) {
  /* eslint-disable no-control-regex, no-useless-escape, prettier/prettier */
  const regex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/

  /* eslint-enable no-control-regex, no-useless-escape, prettier/prettier */

  return regex.test(String(email))
}
