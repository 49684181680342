import '@/assets/javascripts/on/polyfills'
import './namespace'
import './helpers/localize'
import './helpers/validate-email'
import './utility/contentful-imagepath-optimizer'
import './utility/debug' // used in DY client-side
import './utility/mobile-scroll-helper'
import './utility/smooth-scroller'
import './utility-constant/cookie'
import './utility-constant/debouncer'
import './utility-constant/throttler'
import './utility-constant/touch-device'
import {
  initLegacyUtilities,
  showSmoothLoadElementsOnNavReady,
  waitForLegacyUtility,
} from '@/services/legacy-script-loader'

initLegacyUtilities()
showSmoothLoadElementsOnNavReady()

On.waitForLegacyUtility = waitForLegacyUtility
