/**
 * @file SmoothScroller
 * @author michael@on-running.com (heavily inspired by Christian Sany https://www.christiansany.ch/)
 *
 * @module utility/smoothScroller
 *
 * The smoothScroller utility allows to scroll to a certain element with a desired easing
 *
 */
import './easings'
;(function () {
  var defaults = {
    selector: '[data-scroll]',
    targetAttr: 'data-scroll-target',
    offsetAttr: 'data-scroll-offset',
    delayAttr: 'data-scroll-delay',
    durationAttr: 'data-scroll-duration',
    easingAttr: 'data-scroll-easing',
    blockAttr: 'data-scroll-blocker',
    duration: 1000,
    delay: 0,
    offset: 0,
    easing: 'easeInOutCubic',
    callback: function () {},
    element: document.documentElement,
  }

  // Scroll Factory
  // Used by the click handler setup in the init function
  // Can also be used to directly scroll to a certain position (target === Number) without using the click handler in the init function

  var scroll = function (target, options) {
    var instance = {},
      settings = Object.assign({}, defaults, options),
      targetPosition,
      getCoords = function (elem) {
        var box = elem.getBoundingClientRect(),
          body = document.body,
          docEl = settings.element,
          scrollTop = docEl.scrollTop || window.pageYOffset || body.scrollTop,
          scrollLeft = docEl.scrollLeft || window.pageXOffset || body.scrollLeft,
          clientTop = docEl.clientTop || body.clientTop || 0,
          clientLeft = docEl.clientLeft || body.clientLeft || 0,
          top = box.top + scrollTop - clientTop,
          left = box.left + scrollLeft - clientLeft

        return {
          top: Math.round(top),
          left: Math.round(left),
        }
      }
    if (typeof target !== 'number') {
      if (target instanceof HTMLElement === false) {
        throw new Error('The provided target must be a HTMLElement:', target)
      } else {
        targetPosition = getCoords(target).top
      }
    } else {
      targetPosition = target
    }

    // It is possible to pass the offset as a function
    if (typeof settings.offset === 'function') {
      settings.offset = settings.offset(targetPosition)
    }

    // If the desired targetPosition is smaller than 0, just scroll to the top of the page
    if (targetPosition + parseInt(settings.offset) < 0) {
      targetPosition = 0
    } else {
      targetPosition = targetPosition + parseInt(settings.offset)
    }

    var currentPosition = settings.element.scrollTop || window.pageYOffset,
      scrollDifference = targetPosition - currentPosition,
      startTime = null,
      canceled = false,
      progress,
      nextPosition,
      scrollAnim = function (time) {
        // Cancel animation if the cancel method got called
        if (canceled) return

        if (startTime === null) {
          startTime = time
        }

        // Calculate progress (time passed since start)
        progress = time - startTime

        // Calculate next position with easing
        nextPosition =
          scrollDifference * On.easings[settings.easing]((1 / settings.duration) * progress)

        if (settings.element.scrollTo) settings.element.scrollTo(0, nextPosition + currentPosition)

        if (progress > settings.duration) {
          return settings.callback()
        }

        requestAnimationFrame(scrollAnim)
      }

    // Kick off the animation
    requestAnimationFrame(scrollAnim)

    // Public functions
    instance.cancel = function () {
      canceled = true
    }

    return instance
  }

  // Disable and reenable other scrolling actions during the the smoothscroll action (https://stackoverflow.com/a/4770179)
  // left: 37, up: 38, right: 39, down: 40, spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
  var keys = {
    37: 1,
    38: 1,
    39: 1,
    40: 1,
  }

  var preventDefault = function (e) {
    const event = e || window.event
    if (event.preventDefault) event.preventDefault()
    event.returnValue = false
  }

  var preventDefaultForScrollKeys = function (e) {
    if (keys[e.keyCode]) {
      preventDefault(e)
      return false
    }
  }

  var disableScroll = function () {
    if (window.addEventListener) {
      // older FF
      window.addEventListener('DOMMouseScroll', preventDefault, false)
    }
    window.onwheel = preventDefault // modern standard
    window.onmousewheel = document.onmousewheel = preventDefault // older browsers, IE
    window.ontouchmove = preventDefault // mobile
    document.onkeydown = preventDefaultForScrollKeys
  }

  var enableScroll = function (delay) {
    setTimeout(function () {
      if (window.removeEventListener) {
        window.removeEventListener('DOMMouseScroll', preventDefault, false)
      }
      window.onmousewheel = document.onmousewheel = null
      window.onwheel = null
      window.ontouchmove = null
      document.onkeydown = null
    }, delay)
  }

  /**
   * Initialize module
   *
   * @param {object} options - Override default settings with options object.
   *
   * The options object accepts the following parameters:
   *
   * selector {string} (0-1): all elements that match this selection will trigger a smoothScroll action
   * targetAttr {string} (0-1): target to scroll to - add this on the trigger like so e.g. data-scroll-target="#typefaces"
   * offsetAttr {number} (0-1): if you don't want to scroll exactly to the target, you can offset it by adding the value to the trigger element, e.g. data-scroll-offset="-92"
   * durationAttr {number} (0-1): duration of the scroll event, set on the trigger, e.g. data-scroll-duration="5000"
   * easingAttr {string} (0-1): to change standard easing, pass desired easing value on the trigger element, e.g. data-scroll-easing="linear"
   * blockAttr {string} (0-1): scroll elements with this attribute will get ignored for scrolling
   * duration {string} (0-1): standard duration if no specific duration was specified on element
   * offset {string} (0-1): standard offset if no specific offset was specified on element
   * delay {string} (0-1): delay until when the scroll event is fired
   * easing {string} (0-1): standard easing if no specific easing was specified on element
   * callback {function} (0-1): callback that gets executed after scroll animation has ended
   *
   */

  // Scrollinstance Factory
  var init = function (options) {
    var instance = {},
      settings = Object.assign({}, defaults, options)

    /**
     * Clickhandler
     *
     * @param {Event} e - click event
     */
    var handler = function (e) {
      var trigger = e.target.closest(settings.selector)

      // Check if trigger is found
      if (trigger !== null && !trigger.hasAttribute(settings.blockAttr)) {
        e.preventDefault()
        var target = document.querySelector(trigger.getAttribute(settings.targetAttr))

        if (target instanceof HTMLElement === false) {
          //eslint-disable-next-line no-console
          console.error(
            trigger.getAttribute(settings.targetAttr) + ' was not found in the document!'
          )
        } else {
          // Read options to pass to scroll function
          var options = {
            duration: trigger.hasAttribute(settings.durationAttr)
              ? trigger.getAttribute(settings.durationAttr)
              : settings.duration,
            offset: trigger.hasAttribute(settings.offsetAttr)
              ? trigger.getAttribute(settings.offsetAttr)
              : settings.offset,
            delay: trigger.hasAttribute(settings.delayAttr)
              ? trigger.getAttribute(settings.delayAttr)
              : settings.delay,
            easing: trigger.hasAttribute(settings.easingAttr)
              ? trigger.getAttribute(settings.easingAttr)
              : settings.easing,
            callback: settings.callback,
          }

          // Scroll to desired location
          setTimeout(function () {
            scroll(target, options)

            // Disable other scrolling than smoothscrolling temporarily and reenable it afterwards again
            disableScroll()
            enableScroll(settings.duration)
          }, options.delay)
        }
      }
    }

    // Add delegate to document
    document.addEventListener('click', handler)

    /**
     * Destroys this instance
     */
    instance.destroy = function () {
      // Remove delegate from document
      document.removeEventListener('click', handler)
    }

    return instance
  }

  On.initSmoothScroller = init
  On.smoothScrollTo = scroll
})()
