/**
 * @file Debugger
 * @author pierre-michel@on-running.com
 *
 * @module utility/debugger
 *
 * Different methods that only have effects when the url parameter "debug" is present
 */

window.On.Debugger = {
  isActive: window.URLSearchParams
    ? new URLSearchParams(window.location.search).has('debug')
    : false,

  log(data) {
    if (this.isActive) console.log(data) // eslint-disable-line
  },
}
